<template>
  <div>
    <h2 class="mb-4">{{$t('Instant Payouts')}}</h2>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="payoutData"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Payout Date')}}</shipblu-th>
        <shipblu-th>{{$t('Payout Method')}}</shipblu-th>
        <shipblu-th>{{$t('Payout Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Download')}}</shipblu-th>
      </template>
      <template slot-scope="{ data }">
        <shipblu-tr class="inactive-link" :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].number">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Payout Date')}}</p>
            {{ data[indextr].payout_date }}
          </shipblu-td>
          
          <shipblu-td class="order-5" :data="data[indextr].payout">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Payout Method')}}</p>
            <template v-if="data[indextr].payout_method">
              <span class="capitalize">Type: {{data[indextr].payout_method.bank ? 'Bank' : 'Wallet'}}</span><br/>
              <span v-if="data[indextr].payout_method.bank">Name: {{data[indextr].payout_method.bank.name}}</span><br/>
              <span>Number: {{data[indextr].payout_method.bank ? data[indextr].payout_method.bank.account_number : data[indextr].payout_method.wallet.number}}</span><br/>
            </template>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].net_cod_due">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p v-on:click.stop class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ Number(data[indextr].payout_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].pdf_link" class="active-link order-8">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Download')}}</p>
            <a @click="downloadInstantPayout(data[indextr])" target="_blank" class="hover:underline">{{$t('PDF')}}</a> /
            <a @click="downloadExcelInstantPayout(data[indextr])" target="_blank" class="hover:underline">{{$t('Excel')}}</a>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>
<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      tabs: ['Needs Review', 'All'],
      tabsDic: {'0': 'Needs Review', '1': 'All'},
      tabsNameDic: {'Needs Review': 0, 'All': 1},
      payoutData: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      file_URl: '',
      tableLoader: false,
      selected: []
    }
  },
  watch: {
    '$route.query.tab' () {
      this.currentPage = 1
      this.loadPayouts()
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPayouts()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadPayouts()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    loadPayouts () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/instant/payouts/${query}`, 'get', null, true,
        (response) => {
          this.payoutData = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
        }
      )
    },
    downloadInstantPayout (data) {
      sendRequest(false, false, this, `api/v1/instant/payouts/${data.id}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelInstantPayout (data) {
      sendRequest(false, false, this, `api/v1/instant/payouts/${data.id}/excel/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCard,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPayouts()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>